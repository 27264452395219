import Introduction from "layouts/mocktest/Introduction";
import FillInBlanksAnswerMock from "layouts/mocktest/listening/FTBQ/answers";
import HighlightSummaryAnswerMock from "layouts/mocktest/listening/HSQ/answers";
import IncorrectWordsMock from "layouts/mocktest/listening/IWQ/answers";
import MissingWordAnswerMock from "layouts/mocktest/listening/MWQ/answers";
import SingleAnswerListeningMock from "layouts/mocktest/listening/SA/answers";
import SummarizeTextAcedimicMock from "layouts/mocktest/listening/STA/answers";
import DictationAnswersMock from "layouts/mocktest/listening/WFD/dictationanswers";

import FillInBlanksMock from "layouts/mocktest/reading/FITB/answers";
import MultipleAnswerReadingMock from "layouts/mocktest/reading/MA/answers";
import ReorderParagraphsMock from "layouts/mocktest/reading/ROP/answers";
import FillInBlanksSelectMock from "layouts/mocktest/reading/RWF/answers";
import SingleAnswerReadingMock from "layouts/mocktest/reading/SA/answers";

import ImageDescriptionMock from "layouts/mocktest/speaking/DIQ/answers";
import ReadAloudMock from "layouts/mocktest/speaking/RAQ/answers";
import RepeatSentenceAnswersMock from "layouts/mocktest/speaking/RS/answers";
import RespondToSituationMock from "layouts/mocktest/speaking/RTS/answers";
import ShortAnswerMock from "layouts/mocktest/speaking/SAQ/answers";
// import EssayAnswersMock from 'layouts/mocktest/writing/ESSAY/answers';

import WriteEmailMock from "layouts/mocktest/writing/WE/answers";

import React, { useEffect, useState } from "react";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { API_PATH } from "services/apipath";
import { getApi } from "services/axiosInstance";
import { postApi } from "services/axiosInstance";
import { Box, Button, Container, Typography, Modal } from "@mui/material";
import AccessAlarmsIcon from "@mui/icons-material/AccessAlarms";
import { Send } from "@mui/icons-material";
import SummarizeTextAcedimicMockWrite from "layouts/mocktest/writing/STA/answers";
import MultipleAnswerListeningMock from "layouts/mocktest/listening/MA/answers";
import MultipleAnswerListeningMock1 from "layouts/mocktest/listening/MA/answers";
import EssayAnswersAcedimicMockWrite from "layouts/mocktest/writing/ESSAY/answers";
import RetellLectureMockTest from "layouts/mocktest/speaking/RL/answers";
import FirstStepModal from "components/Modal/FullTestStepModals/FirstStepModal";
import { useRef } from "react";
import SecondStepModal from "components/Modal/FullTestStepModals/SecondStepModal";
import ThirdStepModal from "components/Modal/FullTestStepModals/ThirdStepModal";
import CircularProgress from "@mui/material/CircularProgress";

export default function FullMockTest({ category, subcategory, base }) {
  const { id } = useParams();
  const [timeRemaining, setTimeRemaining] = useState(7200); // Total test duration in seconds (2 hrs 20 mins)
  const [currentPart, setCurrentPart] = useState(null);
  const [currentQuestions, setCurrentQuestions] = useState([]);
  const [questionsData, setQuestionsData] = useState([]);
  const [initialComponent, setInitialComponent] = useState(null);
  // Local state for category and subcategory
  const [currentCategory, setCurrentCategory] = useState(category); // Default category
  const [currentSubCategory, setCurrentSubcategory] = useState(subcategory); // Default subcategory
  const navigate = useNavigate();
  const location = useLocation();
  const { testId, fromResume } = location.state || {};

  const handleTestComplete = () => {
    localStorage.setItem("lastId", false);
    navigate("/dashboard");
  };

  // data next
  const prevPartRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [arrayofQuestions, setArrayOfQuestions] = useState([]);
  const [fullTestName, setFullTestName] = useState("");
  const [last, setLast] = useState(false);

  const GetIdQuestion = async (id) => {
    try {
      const res = await getApi(`${API_PATH.STUDENTS.GET_QUESTION_BY_ID}/${id}`);
      if (res.status === 200) {
        // alert(currentCategory)
        setQuestionsData(res.data.data);
        setCurrentCategory(res.data.data.module_type[0]);
        setCurrentSubcategory(res.data.data.module_subcategory[0]);
        // setCurrentQuestions(res.data.data.QTIDS[0])
      }
    } catch (error) { }
  };

  const [isLoading, setIsLoading] = useState(false);

  const GetIds = async () => {
    try {
      setIsLoading(true);
      const res = await getApi(
        `${API_PATH.MOCK_TEST.GET_MOCK_TEST_BY_ID}/${id}`
      );
      if (res.status === 200) {
        if (res.data.data.TestCategory === "Full Test") {
          // 140 * 60 : 30 * 60
          setTimeRemaining(120 * 60);
        } else {
          setTimeRemaining(30 * 60);
        }
        setFullTestName(res.data.data.TestName);
        
        // Ensure QTIDS exists and is an array
        const questionIds = Array.isArray(res.data.data.QTIDS) ? res.data.data.QTIDS : [];
        setArrayOfQuestions(questionIds);
        
        if (testId && questionIds.length > 0) {
          const foundIndex = questionIds.findIndex((q) => q === testId);
          if (foundIndex !== -1) {
            setCurrentIndex(foundIndex);
            GetIdQuestion(questionIds[foundIndex]);
          }
        } else if (questionIds.length > 0) {
          // If no testId but we have questions, load the first one
          GetIdQuestion(questionIds[0]);
        }
      }
    } catch (error) {
      console.error("Error fetching test data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    GetIds();
  }, []);

  // console.log(questionsData);

  const fetchNext = async () => {
    if (isLoading) return;
    
    if (!arrayofQuestions || arrayofQuestions.length === 0) {
      console.error("No questions available");
      return;
    }
    
    if (currentIndex < arrayofQuestions.length - 1) {
      const nextIndex = currentIndex + 1;
      setCurrentIndex(nextIndex);
      setLast(nextIndex);
      await GetIdQuestion(arrayofQuestions[nextIndex]);
    } else {
      alert("You have completed all questions!");
      // Optionally navigate to results or dashboard
      handleTestComplete();
    }
  };

  const fetchPrev = async () => {
    localStorage.setItem("lastId", false);
    if (currentIndex > 0) {
      const prevIndex = currentIndex - 1;
      setCurrentIndex(prevIndex);
      await GetIdQuestion(arrayofQuestions[prevIndex]);
    } else {
      alert("Already at the first ID.");
    }
  };

  useEffect(() => {
    if (currentIndex === arrayofQuestions.length - 1) {
      localStorage.setItem("lastId", true);
    } else {
      localStorage.setItem("lastId", false);
    }
  }, [currentIndex, arrayofQuestions]);

  // Add this useEffect to monitor state changes
  useEffect(() => {
    if (arrayofQuestions && arrayofQuestions.length > 0 && !questionsData) {
      GetIdQuestion(arrayofQuestions[currentIndex]);
    }
  }, [arrayofQuestions]);

  // Define time intervals and parts
  const timeIntervals = [
    { start: 0, end: 4020, part: "Speaking and Writing" }, // 54–67 minutes
    { start: 4021, end: 5820, part: "Reading" }, // 29–30 minutes
    { start: 5821, end: 8400, part: "Listening (Part 3)" }, // 30–43 minutes
  ];

  const handleNext = () => {
    const subcategories = progressionMap[currentCategory];
    const currentIndex = subcategories.indexOf(currentSubCategory);

    if (currentIndex < subcategories.length - 1) {
      // Move to the next subcategory within the same category
      setCurrentSubcategory(subcategories[currentIndex + 1]);
    } else {
      // Move to the next category
      const categoryIndex = categoryOrder.indexOf(category);
      if (categoryIndex < categoryOrder.length - 1) {
        const nextCategory = categoryOrder[categoryIndex + 1];
        setCurrentCategory(nextCategory);
        setCurrentSubcategory(progressionMap[nextCategory][0]); // Start with the first subcategory of the next category
      }
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
  };
  const [open, setOpen] = useState(true);
  const [secondModalOpen, setSecondModalOpen] = useState(false)
  const [thirdModalOpen, setThirdModalOpen] = useState(false)

  const [canContinue, setCanContinue] = useState(true);

  // Define the category-subcategory progression
  const progressionMap = {
    Speaking: [
      "Read Aloud",
      "Repeat Sentence",
      "Describe Image",
      "Respond to Situation",
      "Short Answer",
    ],
    Writing: ["Summarize Text", "Write Email"],
    Reading: [
      "RW Fill in Blanks",
      "Multiple Answers",
      "Reorder Paragraph",
      "Fill in Blanks",
      "Single Answer",
    ],
    Listening: [
      "Summarize Text",
      "Multiple Answers",
      "Fill in the Blanks",
      "Single Answer",
      "Missing Words",
      "Incorrect Words",
    ],
  };

  const categoryOrder = ["Speaking", "Writing", "Reading", "Listening"]; // Order of categories

  // Define the question structure for each part
  const questionStructure = {
    "Speaking and Writing": [
      { type: "Read Aloud", count: 7 },
      { type: "Repeat Sentence", count: 6 },
      { type: "Describe Image", count: 5 },
      { type: "Respond to Situation", count: 1 },
      { type: "Retell Lecture", count: 1 },
      { type: "Short Answer", count: 5 },
      { type: "Summarize Text", count: 1 },
      { type: "Write Email", count: 1 },
      { type: "Essay", count: 1 },
    ],
    " Reading (Part 2)": [
      { type: "RW Fill in Blanks", count: 6 },
      { type: "Multiple Answers", count: 1 },
      { type: "Reorder Paragraph", count: 2 },
      { type: "Fill in Blanks", count: 4 },
      { type: "Single Answer", count: 2 },
    ],
    "Listening (Part 3)": [
      { type: "Summarize Text", count: 1 },
      { type: "Multiple Answers", count: 2 },
      { type: "Fill in the Blanks", count: 2 },
      { type: "Single Answer", count: 2 },
      { type: "Missing Words", count: 1 },
      { type: "Highlight Summary", count: 1 },
      { type: "Incorrect Words", count: 2 },
    ],
  };

  const categorySubcategoryMap = {
    Speaking: {
      "Read Aloud": (
        <ReadAloudMock questionData={questionsData} onNext={fetchNext} />
      ),
      "Repeat Sentence": (
        <RepeatSentenceAnswersMock
          questionData={questionsData}
          onNext={fetchNext}
        />
      ),
      "Describe Image": (
        <ImageDescriptionMock questionData={questionsData} onNext={fetchNext} />
      ),
      "Respond to Situation": (
        <RespondToSituationMock
          questionData={questionsData}
          onNext={fetchNext}
        />
      ),

      "Retell Lecture": (
        <RetellLectureMockTest
          questionData={questionsData}
          onNext={fetchNext}
        />
      ),

      "Short Answer": (
        <ShortAnswerMock questionData={questionsData} onNext={fetchNext} />
      ),
    },
    Writing: {
      "Essay": <EssayAnswersAcedimicMockWrite
        questionData={questionsData}
        onNext={fetchNext}
        timerStart={canContinue}
      />,

      "Summarize Text": (
        <SummarizeTextAcedimicMockWrite
          questionData={questionsData}
          onNext={fetchNext}
        />
      ),
      "Write Email": (
        <WriteEmailMock questionData={questionsData} onNext={fetchNext} />
      ),
    },
    Reading: {
      "RW Fill in Blanks": (
        <FillInBlanksSelectMock
          questionData={questionsData}
          onNext={fetchNext}
        />
      ),
      "Multiple Answers": (
        <MultipleAnswerReadingMock
          questionData={questionsData}
          onNext={fetchNext}
        />
      ),
      "Reorder Paragraph": (
        <ReorderParagraphsMock
          questionData={questionsData}
          onNext={fetchNext}
        />
      ),
      "Fill in Blanks": (
        <FillInBlanksMock questionData={questionsData} onNext={fetchNext} />
      ),
      "Single Answer": (
        <SingleAnswerReadingMock
          questionData={questionsData}
          onNext={fetchNext}
        />
      ),
    },
    Listening: {
      "Single Answer": (
        <SingleAnswerListeningMock
          questionData={questionsData}
          onNext={fetchNext}
        />
      ),
      "Multiple Answers": (
        <MultipleAnswerListeningMock1
          questionData={questionsData}
          onNext={fetchNext}
        />
      ),
      "Write From Dictation": (
        <DictationAnswersMock questionData={questionsData} onNext={fetchNext} />
      ),
      "Highlight Summary": (
        <HighlightSummaryAnswerMock
          questionData={questionsData}
          onNext={fetchNext}
        />
      ),
      "Incorrect Words": (
        <IncorrectWordsMock questionData={questionsData} onNext={fetchNext} />
      ),
      "Fill in The Blanks": (
        <FillInBlanksAnswerMock
          questionData={questionsData}
          onNext={fetchNext}
        />
      ),
      "Missing Word": (
        <MissingWordAnswerMock
          questionData={questionsData}
          onNext={fetchNext}
        />
      ),
      "Summarize Text": (
        <SummarizeTextAcedimicMock
          questionData={questionsData}
          onNext={fetchNext}
        />
      ),
    },
  };

  //   =======================================   Timer  =========================================

  // Convert timeRemaining (in seconds) to minutes and seconds
  useEffect(() => {
    if (canContinue && timeRemaining > 0) {
      const timer = setTimeout(
        () => setTimeRemaining((prev) => prev - 1),
        1000
      );
      return () => clearTimeout(timer); // Cleanup on unmount or update
    }
  }, [timeRemaining, canContinue]);

  const formatTime = () => {
    const hours = Math.floor(timeRemaining / 3600);
    const minutes = Math.floor((timeRemaining % 3600) / 60);
    const seconds = timeRemaining % 60;

    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}:${String(seconds).padStart(2, "0")}`;
  };

  const header = () => {
    const date = new Date();
    const currentMonthAndDate = `${String(date.getMonth() + 1).padStart(
      2,
      "0"
    )}-${date.getFullYear()}`;
    return (
      <header>
        <Box
          sx={{
            // position: "fixed",
            // top: 0,
            // left: 0,
            // right: 0,
            bgcolor: "white",
            zIndex: 1000,
          }}
        >
          <Container maxWidth="lg">
            <Box
              sx={{
                p: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: "5px solid #80CBC4",
                verticalAlign: "middle",
              }}
            >
              {/* <IconButton sx={{ color: "#fff" }} onClick={() => navigate(-1)}>
                  <i className="fas fa-arrow-left" />
                </IconButton> */}

              <Typography variant="h4" sx={{ color: "black", ml: "20px" }}>
                {/* {questionsData.question_title}  */}
                {fullTestName}
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  ml: "auto",
                }}
              >
                <Typography
                  sx={{ color: "black", fontSize: "15px", marginRight: "16px" }}
                >
                  {/* {currentQuestion}/{questionData.totalQuestions} */}
                  {currentIndex + 1}/{arrayofQuestions.length}
                </Typography>

                <Box
                  sx={{
                    color: "#000000",
                    border: "1px solid grey",
                    paddingX: "5px",
                    paddingY: "3px",
                    borderRadius: "6px",
                    display: "flex",
                    justifyContent: "space-evenly",
                    verticalAlign: "middle !important",
                  }}
                >
                  <AccessAlarmsIcon
                    sx={{
                      marginRight: "5px",
                      fontSize: "16px !important",
                      marginTop: "4px",
                    }}
                  />

                  <Typography sx={{ fontSize: "15px" }}>
                    {formatTime()}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Container>
        </Box>
        {/* <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            sx={{ textAlign: "center" }}
            id="modal-modal-title"
            variant="h5"
            component="h2"
          >
            Part 3 : Listening
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, fontSize: "16px" }}
          >
            Timing : 30 minutes
          </Typography>
          <Typography sx={{ mt: 2, fontSize: "16px" }}>
            Remember : Wear Headphones
          </Typography>
          <Button
            sx={{
              background: "#00ca71",
              color: "#fff",
              border: "1px solid #00ca71",
              borderRadius: "100px",
              width: "100%",
              py: ".5rem",
              my: ".5rem",
            }}
            onClick={fetchNext}
          >
            Start
          </Button>
        </Box>
      </Modal> */}
      </header>
    );
  };

  //   =======================================   Pick Page  =========================================

  const PickPage = () => {
    const categoryMap = categorySubcategoryMap[currentCategory];

    if (categoryMap) {
      const component = categoryMap[currentSubCategory];
      if (component) {
        setInitialComponent(component);
      }
    } else {
      setInitialComponent(<div>No component for this category</div>);
    }
  };

  const onNextClicked = async () => {
    console.log("Button clicked, calling child API...");
    setIsLoading(true); // Show loader
    try {
      await fetchNext();
    } finally {
      setIsLoading(false); // Hide loader
    }
  };

  // useEffect(() => {
  //   if (prevPartRef.current !== currentPart) {
  //     if (currentPart) {
  //       if (currentPart === "Reading") {
  //         // setCanContinue(false);
  //         setSecondModalOpen(true);
  //       } else if (currentPart === "Listening (Part 3)") {
  //         // setCanContinue(false)
  //         setThirdModalOpen(true);
  //       }
  //     }
  //     prevPartRef.current = currentPart;
  //   }
  // }, [currentPart]);
  // Determine the current part based on timeRemaining
  useEffect(() => {
    const interval = timeIntervals.find(
      (interval) =>
        timeRemaining >= interval.start && timeRemaining <= interval.end
    );

    if (interval) {
      setCurrentPart(interval.part);
      setCurrentQuestions(questionStructure[interval.part]);

      // Update the category and subcategory based on the current part
      const categoryIndex = categoryOrder.findIndex((cat) =>
        Object.keys(progressionMap[cat]).includes(interval.part)
      );

      if (categoryIndex !== -1) {
        const newCategory = categoryOrder[categoryIndex];
        setCurrentCategory(newCategory);

        // Get the subcategory progression for the selected category
        const subcategories = progressionMap[newCategory];

        if (subcategories) {
          // Dynamically determine the subcategory to display
          const elapsedPartTime = timeRemaining - interval.start;
          const subcategoryIndex = Math.floor(
            (elapsedPartTime / (interval.end - interval.start)) *
            subcategories.length
          );

          setCurrentSubcategory(subcategories[subcategoryIndex]);
        }
      }
    }
  }, [timeRemaining]);

  useEffect(() => {
    if (Object.keys(questionsData).length > 0) {
      PickPage(); // Update component once questionsData is fetched
    }
  }, [questionsData, canContinue]);

  return (
    <>
      {header()}
      {initialComponent}
      {/* <Container>
        <Box
          sx={{
            mt: 3,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            verticalAlign: "middle",
          }}
        >
          <Button variant="outlined" sx={{ color: "black !important" }}>
            Save & Exit
          </Button>

          <Box
            sx={{ display: "flex", alignItems: "center", gap: 1, ml: "auto" }}
          >
            <Button
              variant="contained"
              sx={{ color: "white !important" }}
              endIcon={<Send />}
              onClick={fetchPrev}
            >
              Prev.
            </Button>

            <Button
              variant="contained"
              sx={{ color: "white !important" }}
              endIcon={!isLoading && <Send />}
              onClick={onNextClicked}
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress size={24} color="inherit" /> : currentIndex === arrayofQuestions.length - 1 ? "Submit" : "Next"}
            </Button>
          </Box>
        </Box>
      </Container> */}
      {/* <FirstStepModal open={open} setOpen={setOpen} setCanContinue={setCanContinue} />
      <SecondStepModal open={secondModalOpen} setOpen={setSecondModalOpen} />
      <ThirdStepModal open={thirdModalOpen} setOpen={setThirdModalOpen} /> */}
    </>
  );
}

